import request from "./request.js";
import baseUrl from "./config.js"
// import qs from "qs";

//提交
export function quotedPriceList(params) {
	return request({
		url: baseUrl + "/spider/web/quotedPrice/list",
		method: "get",
		params: params
	});
}

// 机构列表查询 /spider/web/mechanism/list
export function mechanismList(params) {
	return request({
		url: baseUrl + "/spider/web/mechanism/list",
		method: "get",
		data: params
	});
}

// 新增报价单
export function saveOrUpdate(params) {
	return request({
		url: baseUrl + "/spider/web/quotedPrice/saveOrUpdate",
		method: "post",
		data: params
	});
}

// 获取报价明细
export function detailedTreeList(params) {
	return request({
		url: baseUrl + "/spider/web/detailed/treeList",
		method: "get",
		params: params
	});
}

// 获取报价单详情
export function quotedPrice(params) {
	return request({
		url: baseUrl + "/spider/web/quotedPrice/" + params,
		method: "get",
	});
}

// 删除报价单
export function quotedPriceRemove(id) {
	return request({
		url: baseUrl + `/spider/web/quotedPrice/remove/${id}`,
		method: "post",
	});
}