<template>
	<div id="history">
		<!-- 报价蜘蛛 报价单记录 -->
		<div class="title">报价单</div>

		<div class="formDataShow">
			<div class="name">
				{{quotedPriceData.quotedPriceName}}
			</div>

			<div class="detailed">
				<div class="tit">报价明细</div>
				<div class="item" v-for="(item,i) in quotedPriceData.detaileds" :key="i">
					<div class="itemT">{{item.detaiiledName}} <span>合计：<b>￥{{ numberToCurrencyNo(item.price) }}</b></span></div>
					<div class="list">
						<div class="row" v-for="(item2,i2) in item.children" :key="i2">
							<div class="info">
								<!-- <el-image style="width: 100px; height: 100px" :src="item2.imagePath"
									fit="cover"></el-image> -->
								<div class="t"><i></i>{{item2.detaiiledName}}</div>
								<div class="n"><b>数量</b>：{{item2.num}}张</div>
							</div>
							<div class="price"><b>￥</b>{{ numberToCurrencyNo(item2.price) }}</div>
						</div>
					</div>
					<el-divider></el-divider>
				</div>

				<div class="TotalPrice">
					总计： <span>￥{{ numberToCurrencyNo(quotedPriceData.price) }}</span>
				</div>
				<div class="submit">
					<el-button type="primary" @click="ChangeForm">修改</el-button>
				</div>
			</div>
		</div>


	</div>
</template>

<script>
	import {
		quotedPrice, // 获取报价单详情
		// mechanismList // 机构列表查询
	} from '@/request/apiReference.js';
	export default {
		name: 'history',
		components: {},
		data() {
			return {
				quotedPriceData: '',
				detaileds: []
			}
		},
		created() {
			if (this.$route.query.id) {
				this.getquotedPrice(this.$route.query.id)
			} else {
				this.$notify({
					title: '提示',
					message: `没有找到数据`,
					type: 'info',
					position: 'bottom-right'
				});
				this.$router.push({
					name: "Reference"
				})
			}
		},
		mounted() {},
		methods: {
			// 获取报价单详情
			getquotedPrice(id) {
				quotedPrice(id).then(res => {
					let data = res.data;
					this.quotedPriceData = data;
				})
			},

			ChangeForm() {
				this.$router.push({
					name: 'ReferenceForm',
					query: {
						id: this.quotedPriceData.id
					}
				})
			},
			
			// 千位符
			numberToCurrencyNo(value) {
				if (!value) return 0
				// 获取整数部分
				const intPart = Math.trunc(value)
				// 整数部分处理，增加,
				const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
				// 预定义小数部分
				let floatPart = ''
				// 将数值截取为小数部分和整数部分
				const valueArray = value.toString().split('.')
				if (valueArray.length === 2) { // 有小数部分
					floatPart = valueArray[1].toString() // 取得小数部分
					return intPartFormat + '.' + floatPart
				}
				return intPartFormat + floatPart
			}

		}
	}
</script>

<style lang="less" scoped>
	#history {
		padding: 50px;
		background-color: #f2f2f2;
		min-height: 100vh;

		.title {
			font-size: 28px;
			line-height: 3;
			color: #181B1A;
		}

		.formDataShow {

			.name {
				background-color: #fff;
				padding: 50px;
				border-radius: 20px;
				font-size: 30px;
				color: #181B1A;
				line-height: 24px;
				margin-bottom: 50px;
			}

			.detailed {
				background-color: #fff;
				padding: 50px;
				border-radius: 20px;
				font-size: 30px;
				color: #181B1A;
				line-height: 24px;

				.tit {
					font-size: 24px;
					margin-bottom: 30px;
					line-height: 1;
					color: rgba(24, 27, 26, 0.8);
				}

				.item {
					margin-bottom: 50px;

					.itemT {
						display: flex;
						align-items: center;
						justify-content: space-between;
						font-size: 22px;
						margin-bottom: 30px;
						color: #181B1A;

						span {
							font-size: 16px;
						}
					}

					.list {
						background: rgba(104, 46, 220, 0.04);
						border-radius: 8px;
						padding: 15px 0;

						.row {
							padding: 15px 30px;
							display: flex;
							flex-direction: row;
							justify-content: space-between;
							align-items: flex-start;
							border-bottom: 1px solid rgba(24, 27, 26, 0.04);

							&:last-child {
								border: 0;
							}

							.info {
								.t {
									font-size: 18px;
									margin-bottom: 5px;
									position: relative;
									padding-left: 10px;

									i {
										counter-reset: none;
										width: 3px;
										border-radius: 2px;
										height: 100%;
										left: 0;
										position: absolute;
										background-color: #682EDC;
									}
								}

								.n {
									padding-left: 50px;
									font-size: 14px;
									font-weight: normal;

									b {
										font-weight: normal
									}
								}
							}

							.price {
								font-size: 20px;

								b {
									font-size: 14px;
								}
							}
						}
					}
				}

				.TotalPrice {
					text-align: right;
					margin-bottom: 30px;
					font-size: 18px;
					color: #181B1A;

					span {
						font-size: 22px;
					}
				}

				.submit {
					display: flex;
					justify-content: flex-end;

					.el-button {}
				}
			}

		}
	}
</style>